<div fxFlexAlign="stretch" class="container">
  <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
  <mat-card>
      <mat-card-header>
        <mat-card-title>User Summary</mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <mat-chip-listbox>
          <mat-chip>
            Account Creation Date : {{ accountCreationDate}}
          </mat-chip>

          <mat-chip>
            Has Profile : {{hasProfile}}
          </mat-chip>

          <mat-chip>
            Has Auth0 : {{hasAuth}}  
          </mat-chip>
          <mat-chip *ngIf="roles.length > 0" >
            Roles : {{roles}}  
          </mat-chip>
          <mat-chip>
            Account Locked : {{accountLock}}
          </mat-chip>

          <mat-chip *ngIf="roles.length === 0" >
            Roles : User  
          </mat-chip>
          
        </mat-chip-listbox>
          
         
        
        <mat-accordion>
          <mat-expansion-panel (afterExpand)="getProfilePlaylist()">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Profile Playlist
              </mat-panel-title>
            </mat-expansion-panel-header>
            
              <div     
                  class="inventory-grid z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                  *ngIf="profilePlaylist.length > 0" >
                    <div class="hidden md:block" fxFlex="30">Name</div>                                   
                    <div class="hidden md:block" fxFlex="30">Category</div>
                    <div class="hidden md:block" fxFlex="40">SignalBundles</div>
              </div>
              <div class="grid" *ngIf="profilePlaylist.length > 0 ;else no_profilePlaylist" class="modified-mc-content">                 
                <div class="inventory-grid grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let playlist of profilePlaylist;">
                  <div>{{ playlist.name }}</div>
                  <div>{{ playlist.category.name }} </div> 
                  <mat-chip-list >
                    <mat-chip *ngFor="let blendItem of playlist.items">
                      <ng-container *ngIf="blendItem && blendItem.blend">{{ blendItem.blend.name }} ({{ blendItem.suggestedDuration}})</ng-container>
                    </mat-chip>
                  </mat-chip-list>
                </div>   
              </div>            
                                                                
              <ng-template #no_profilePlaylist>
                <mat-chip-set >
                  <mat-chip>
                    No Device found.
                  </mat-chip>             
                </mat-chip-set>   
              </ng-template>   
             
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Device List
              </mat-panel-title>
            </mat-expansion-panel-header>
            
              <div     
                  class="inventory-grid z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                  *ngIf="deviceItems.length > 0" >
                    <div class="hidden md:block" fxFlex="20">Code</div>                                   
                    <div class="hidden md:block" fxFlex="40">Device Type</div>
                    <div class="hidden md:block" fxFlex="40">Serial Number</div>
              </div>
              <div class="grid" *ngIf="deviceItems.length > 0 ;else no_device" class="modified-mc-content">                 
                <div class="inventory-grid grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let device of deviceItems;">
                  <div>{{ device.code }}</div>
                  <div>{{ device.deviceType }} </div> 
                  <div>{{ device.serialNumber }} </div> 
                </div>   
              </div>            
                                                                
              <ng-template #no_device>
                <mat-chip-set >
                  <mat-chip>
                    No Device found.
                  </mat-chip>             
                </mat-chip-set>   
              </ng-template>   
             
          </mat-expansion-panel>
  
          <mat-expansion-panel (afterExpand)="getMobileDeviceDetails()">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Mobile Device Details
              </mat-panel-title>
            </mat-expansion-panel-header>
            
              <div     
                  class="inventory-grid2 z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                  *ngIf="mobileDetails.length > 0">
                    <div class="hidden md:block" fxFlex="80">Mobile Device Id</div>                                   
                    <div class="hidden md:block" fxFlex="20">Timezone</div>
                    
              </div>
              <div class="grid" *ngIf="mobileDetails.length > 0 ;else no_mobileDetails" class="modified-mc-content">                 
                <div class="inventory-grid2 grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let mobileDetail of mobileDetails;">
                  <div class="deviceId">{{ mobileDetail.mobileDeviceId }}</div>
                  <div>{{ mobileDetail.timeZone }} GMT </div> 
                  
                </div>   
              </div>            
              <ng-template #no_mobileDetails>
                <mat-chip-set >
                  <mat-chip>
                    No Details found.
                  </mat-chip>             
                </mat-chip-set>
    
              </ng-template>   
             
          </mat-expansion-panel>
  
  
  
          <mat-expansion-panel (afterExpand)="getNotes()">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Account Notes
              </mat-panel-title>
            </mat-expansion-panel-header>
                       
              <div align="end" >
                <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addNote()">          
                  <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                  <span class="ml-2 mr-1">Add Note</span>
                </button>
              </div>                   
             
              <div     
                  class="inventory-grid4 z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                  *ngIf="notesDetails.length > 0">
                    <div class="hidden md:block" fxFlex="50">Description</div>                                   
                    
              </div>
              <div class="grid" *ngIf="notesDetails.length > 0 ;else no_notesDetails" class="modified-mc-content">                 
                <div class="inventory-grid4 grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let note of notesDetails">
                  <div class="deviceId">{{ note.notes }}</div>
                  <div fxLayoutGap="10px grid">
                    <button  (click)="editNote(note)">           
                      <mat-icon [svgIcon]="'heroicons_outline:pencil-alt'"></mat-icon>
                    </button>
        
                    <button  (click)="viewNote(note)">
                      <mat-icon [svgIcon]="'heroicons_outline:eye'"></mat-icon>
                    </button>
      
                    <button (click)="removeNote(note)">
                      <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                    </button>

                  </div>
                            
                           
                </div>   
              </div>               
                                
                 
              <ng-template #no_notesDetails>
                <mat-chip-set >
                  <mat-chip>
                    No Notes found.
                  </mat-chip>             
                </mat-chip-set>
    
              </ng-template>   

          </mat-expansion-panel>


          <mat-expansion-panel (afterExpand)="getUsageLog()">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Playback Summary
              </mat-panel-title>
            </mat-expansion-panel-header>   
              <div>
                <mat-chip *ngIf="slicedUsageDetails.length > 0">
                  Recent Playback : {{ playbackCount}}
                </mat-chip>             
  
                <button *ngIf="fullUsageDetails.length > 0" class="ml-4" mat-flat-button [color]="'primary'" (click)="downloadFile()">          
                  <span class="ml-2 mr-1">Download Full Log</span>
                </button>
  
              </div>             
              <div     
                  class="inventory-grid3 z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                  *ngIf="slicedUsageDetails.length > 0" >
                    <div class="hidden md:block" fxFlex="20">Date</div>                                   
                    <div class="hidden md:block" fxFlex="60">Playback Item</div>
                    <div class="hidden md:block" fxFlex="20">Intended Duration</div>
              </div>
              <div class="grid" *ngIf="slicedUsageDetails.length > 0 ;else no_usagelog" class="modified-mc-content">                 
                <div class="inventory-grid3 grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let detail of slicedUsageDetails;">
                  <div>{{ detail.timestamp }}</div>
                  <div>{{ detail.signalBundleName }} {{ detail.playlistName }} </div> 
                  <div>{{ detail.intendedDuration }} </div> 
                </div>   
              </div>            
                             
              <ng-template #no_usagelog>
                <mat-chip-set >
                  <mat-chip>
                    No Usage Log found.
                  </mat-chip>             
                </mat-chip-set>
    
              </ng-template>   
             
          </mat-expansion-panel>

          <mat-expansion-panel (afterExpand)="getCustomerMemberships()">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Memberships
              </mat-panel-title>
            </mat-expansion-panel-header>  
            <div align="end" >
              <button class="ml-4" mat-flat-button [color]="'primary'" (click)="addCustomerMembership()">          
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add Membership</span>
              </button>
            </div>               
            <div     
                class="inventory-grid6 z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                *ngIf="customerMemberships.length > 0" >
                  <div class="hidden md:block" fxFlex="35">Name</div>                                    
                  <div class="hidden md:block" fxFlex="45">Action</div>
            </div>
            <div class="grid" *ngIf="customerMemberships.length > 0 ;else no_customermembership">                 
              <div class="inventory-grid6 grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let detail of customerMemberships;">
                <div>{{ detail.name }}</div>
                <div fxLayoutGap="10px grid">
                  <button (click)="deleteCustomerMembership(detail)">
                    <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                  </button>
                </div>
                
              </div>   
            </div>            
                             
              <ng-template #no_customermembership>
                <mat-chip-set >
                  <mat-chip>
                    No membership found.
                  </mat-chip>             
                </mat-chip-set>
    
              </ng-template>   
             
          </mat-expansion-panel>

          <mat-expansion-panel (afterExpand)="getDeviceLog()" class="modified-mc-content">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Device Logs
              </mat-panel-title>
            </mat-expansion-panel-header>   
            <ngx-json-viewer [json]="this.DevicelogDetails" ></ngx-json-viewer>  
              <div>               
                <button *ngIf="fullDevicelogDetails.length > 0;else no_devicelog" class="ml-4" mat-flat-button [color]="'primary'" (click)="downloadDeviceLogFile()">          
                  <span class="ml-2 mr-1">Download Device Log</span>
                </button>
  
              </div>                                    
                             
              <ng-template #no_devicelog>
                <mat-chip-set >
                  <mat-chip>
                    No Device Log found.
                  </mat-chip>             
                </mat-chip-set>
    
              </ng-template>   
             
          </mat-expansion-panel>

          <mat-expansion-panel (afterExpand)="getAppLog()"  class="modified-mc-content">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Customer App Logs
              </mat-panel-title>
              
            </mat-expansion-panel-header> 
            <ngx-json-viewer [json]="this.fullApplogDetails"></ngx-json-viewer>  
              <div>               
                <button *ngIf="fullApplogDetails.length > 0;else no_applog" class="ml-4" mat-flat-button [color]="'primary'" (click)="downloadAppLogFile()">          
                  <span class="ml-2 mr-1">Download App Log</span>
                </button>
  
              </div>                                    
                             
              <ng-template #no_applog>
                <mat-chip-set >
                  <mat-chip>
                    No App Log found.
                  </mat-chip>             
                </mat-chip-set>
    
              </ng-template>   
             
          </mat-expansion-panel>

          <mat-expansion-panel (afterExpand)="getIntegration()">
            <mat-expansion-panel-header>
              <mat-panel-title class="headerTitle">
                Integrations
              </mat-panel-title>
            </mat-expansion-panel-header>   
              <div              
                class="inventory-grid7 z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                *ngIf="integration.length > 0" >
                  <div class="hidden md:block">Name</div>                                   
                  <div class="hidden md:block">Category</div>
                  <div class="hidden md:block">Status</div>
                  <div class="hidden md:block">Last Sync</div>
                  <div class="hidden md:block">Details</div>

              </div> 
              
              <div class="grid" *ngIf="integration.length > 0 ;else no_integration" class="modified-mc-content">                 
                <div class="inventory-grid8 grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let item of integration;">
                  <div>{{ item.integrationName }}</div>
                  <div>{{ item.category }} </div> 
                  <div>{{ item.status }} </div> 
                  <div>{{ item.lastSuccessfulSync }} </div> 
                  <div>
                    <button class="ml-4" mat-flat-button [color]="'primary'" (click)="downloadIntegrationDetailsFile(item)">          
                      <span class="ml-2 mr-1">Download</span>
                    </button>
                  </div>
                </div>   
              </div>     
                             
              <ng-template #no_integration>
                <mat-chip-set >
                  <mat-chip>
                    No Integration found.
                  </mat-chip>             
                </mat-chip-set>
    
              </ng-template>   
             
          </mat-expansion-panel>
  
        </mat-accordion>

      </mat-card-content>

     

    
  </mat-card>
</div>

<div mat-dialog-actions>
  <div class="flex items-center justify-between w-full border-t px-8 py-4">                     
    <button class="-ml-4" mat-button  [color]="'warn'" mat-dialog-close mat-dialog-close="true" (click)="closeDialog()">Close</button>
    <div class="flex items-center">             
      <button mat-flat-button [color]="'primary'" *ngIf="!isProfileExists"  (click)="addProfile()">Create and Link Profile</button>
    </div>
    <div class="flex items-center">             
      <button mat-flat-button [color]="'primary'" *ngIf="!hasProfile"  (click)="linkProfile()">Link Profile</button>
    </div>
  </div>
</div>

  

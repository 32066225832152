import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {FormBuilder, FormControl, FormGroup,} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import {fuseAnimations} from "@fuse/animations";
import {FuseConfirmationService} from "@fuse/services/confirmation";
import {InventoryProduct,} from "app/modules/admin/apps/accounts/inventory/inventory.types";
import {InventoryService} from "app/modules/admin/apps/accounts/inventory/inventory.service";
import {CreateUserComponent} from "../../create-user/create-user.component";
import {ChangeEmailComponent} from '../../change-email/change-email/change-email.component';
import {UpdateUserComponent} from '../../update-user/update-user.component';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../../../core/auth/access';
import {AuthServiceClass} from '../../../../../../core/auth/auth.service';
import { UserSummaryComponent } from "../../user-summary/user-summary.component";
import { firstValueFrom } from 'rxjs';
import { MembershipLevel } from "../../../membership-levels/membership-levels";
import { SharedAlertComponent } from "../../../shared-alert/shared-alert.component";

@Component({
  selector: "inventory-list",
  templateUrl: "./inventory.component.html",
  styleUrls: ['./inventory.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class InventoryListComponent
  implements OnInit {
  products: InventoryProduct[] = [];
  fullProducts: InventoryProduct[] = [];
  membershiplevel: MembershipLevel[];
  query = "";
  sort = "email";
  sortOrder = "asc";
  flashMessage: "success" | "error" | null = null;
  isLoading: boolean = false;
  productsCount: number;
  searchInputControl: FormControl = new FormControl();
  selectedProduct: InventoryProduct | null = null;
  selectedProductForm: FormGroup;
  tagsEditMode: boolean = false;
  @ViewChild(MatPaginator) private _paginator: MatPaginator;
  @ViewChild(MatSort) private _sort: MatSort;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseConfirmationService: FuseConfirmationService,
    private _formBuilder: FormBuilder,
    private _inventoryService: InventoryService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    public dialog: MatDialog
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
        this._titleService.setTitle('Hapbee OpsPortal - Accounts');

    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:account')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:account');
    this.canEdit = perms.includes('update:account');
    this.canDelete = perms.includes('delete:account');

    // Create the selected product form
    this.selectedProductForm = this._formBuilder.group({
      email: [""],
      firstName: [""],
      lastName: [""],
      membershipIds: [[]],
      notes: [""],
    });

    // Get products count
    this.productsCount = await this._inventoryService.getProductsCount(this.query);
    this.products = await this._inventoryService.getProducts(
      1,
      25,
      "email", "asc", "");
    this.membershiplevel = await firstValueFrom(this._inventoryService.getMembershipLevels());
    this.fullProducts = this.products;
    this._changeDetectorRef.markForCheck();

    
    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(800),
        switchMap(async (query) => {
          this.closeDetails();
          this.isLoading = true;
          this.query = query;
          this.productsCount = await this._inventoryService.getProductsCount(this.query);
          if (this.productsCount != 0){
            this.products = await this._inventoryService.getProducts(
              this._inventoryService.pagination.page,
              this._inventoryService.pagination.size,
              this.sort,
              this.sortOrder,
              query
            );
          }
          else{
            this.products = [];
          }
          

          try{this.products = this.products.filter(t => t.firstName.toLowerCase().includes(query.toLowerCase()) || t.lastName.toLowerCase().includes(query.toLowerCase()) || t.email.toLowerCase().includes(query.toLowerCase()));}
          catch(e:unknown){}

          this._changeDetectorRef.markForCheck();

        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.products;
  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.query = '';
    this.products = this.fullProducts;
    this.isLoading = false;
  }
 

  async loadProducts() {
    this.products = await this._inventoryService.getProducts(
      this._inventoryService.pagination.page,
      this._inventoryService.pagination.size,
      this.sort,
      this.sortOrder,
      this.query);
      
    this.fullProducts = this.products;
    this._changeDetectorRef.markForCheck();
    this.isLoading = false;
  }

  
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle product details
   *
   * @param productId
   */
  async toggleDetails(productId: string): Promise<void> {
    // If the product is already selected...
    if (this.selectedProduct && this.selectedProduct.id === productId) {
      // Close the details
      this.closeDetails();
      return;
    }

    // Get the product by id
    let product = await this._inventoryService.getProductById(productId)
    // Set the selected product
    this.selectedProduct = product;

    // Fill the form
    this.selectedProductForm.patchValue(product);

    // Mark for check
    this._changeDetectorRef.markForCheck();

  }

  /**
   * Close the details
   */
  closeDetails(): void {
    this.selectedProduct = null;
  }

  /**
   * Toggle the tags edit mode
   */
  toggleTagsEditMode(): void {
    this.tagsEditMode = !this.tagsEditMode;
  }

  openDialog() {
    let dialogref = this.dialog.open(CreateUserComponent, {disableClose: true});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.loadProducts();
      }
    });
  }

  async changeEmail(product: InventoryProduct) {
    let dialogref = this.dialog.open(ChangeEmailComponent, {
      disableClose: true,
      width: '400px',
      data: product
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.loadProducts();
      }
    });

  }

  viewSummary(product: InventoryProduct) {
    let dialogref = this.dialog.open(UserSummaryComponent, {
      disableClose: true,
      width: '900px',
      height: '700px',
      data: product
    });
    
  }

  /**
   * Show flash message
   */
  showFlashMessage(type: "success" | "error"): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(() => {
      this.flashMessage = null;
      this.closeDetails();
      // Mark for check
      this._changeDetectorRef.markForCheck();
    }, 2000);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  async deactivate(product: InventoryProduct) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to deactivate this Account?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }    
      this._inventoryService.lockUser(product.id).subscribe(async () => {
      this.isLoading = true;
      await this.loadProducts();
      this.isLoading = false;
      this.dialog.open(SharedAlertComponent,
        {
          width: 'auto',
          disableClose: false,
          data: {dialogMessage:'User is deactivated successfully'}
      });
      });
    });
  }

  async activate(product: InventoryProduct) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to Activate this Account?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }    
      this._inventoryService.unlockUser(product.id).subscribe(async () => {
      this.isLoading = true;
      await this.loadProducts();
      this.isLoading = false;
      this.dialog.open(SharedAlertComponent,
        {
          width: 'auto',
          disableClose: false,
          data: {dialogMessage:'User is activated successfully'}
      });
      });
    });
  }


  

  edit(product) {
    let dialogref = this.dialog.open(UpdateUserComponent, {
      disableClose: true,
      width: '400px',
      data: product
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.loadProducts();
      }
    });
  }

  delete(product) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this user?'}).afterClosed().subscribe(e => {
      if (e !== 'confirmed') {
        return;
      }
      this._inventoryService.deleteUser(product.id).subscribe({
        next: async () => {
          this.isLoading = false;
          await this.loadProducts();
          this.isLoading = true;
          this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'User is deleted successfully'}
          });
        },
        error: err => {
          alert(err.message);
        }
      });
    });
  }


  reset(product) {
    this._fuseConfirmationService.open({message: 'This will trigger password reset email'}).afterClosed().subscribe(e => {
      if (e !== 'confirmed') {
        return;
      }
      this._inventoryService.resetPassword(product.id).subscribe({
        next: async () => {
          this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Password is reset successfully'}
          });
        },
        error: err => {
          this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Unable to reset password, please contact engineering team'}
          });
        }
      });
    });
  }

  isEmail(email:string):boolean
  {
      var serchfind:boolean;
      let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      serchfind = regexp.test(email);
      return serchfind
  }

  restore() {
    if(this.isEmail(this.query)){
      this._fuseConfirmationService.open({message: `This will trigger account restore for the input value : ${ this.query}`}).afterClosed().subscribe(e => {
        if (e !== 'confirmed') {
          return;
        }      
        this._inventoryService.undeleteUser(this.query).subscribe({
          next: async () => {
            this.dialog.open(SharedAlertComponent,
              {
                width: 'auto',
                disableClose: false,
                data: {dialogMessage:'Account is restored successfully'}
            });
          },
          error: err => {
            this.dialog.open(SharedAlertComponent,
              {
                width: 'auto',
                disableClose: false,
                data: {dialogMessage:`Unable to restore account for email: ${ this.query}, please contact engineering team`}
            });
          }
        });
      });
    }
    else{
      this.dialog.open(SharedAlertComponent,
        {
          width: 'auto',
          disableClose: false,
          data: {dialogMessage:`Searched input is not the valid email`}
      });
    }

    
  }
}

<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form
          class="flex flex-col w-full"
          [formGroup]="selectedProductForm"
        >
          <div fxLayout="row">
  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Name</mat-label>
              <input matInput [formControlName]="'name'"/>
            </mat-form-field>
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Sub Title</mat-label>
              <input matInput [formControlName]="'subTitle'"/>
            </mat-form-field>
  
  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>PlaybackId</mat-label>
              <input matInput type="number" [formControlName]="'playbackId'" max=2147483647/>
            </mat-form-field>
  
            
          </div>
  
          <div fxLayout="row" class="pb-3">
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>SortOrder</mat-label>
              <input matInput type="number" [formControlName]="'sortOrder'" max=2147483647/>
            </mat-form-field>
  
            <mat-checkbox formControlName="sleepMode">Sleep Mode</mat-checkbox>
            <mat-checkbox class="secondary" formControlName="isPubliclyAvailable">Public</mat-checkbox>
            <mat-checkbox class="secondary" formControlName="isHidden">Hidden</mat-checkbox>
          </div>
          
          
          <div fxLayout="row">
  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Category</mat-label>
              <mat-select [formControlName]="'categoryId'">
                <mat-option *ngFor="let cat of categories" [value]="cat.id">
                  {{ cat.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Tags</mat-label>
              <mat-select [formControlName]="'tags'" multiple>
                <mat-option *ngFor="let tag of tags" [value]="tag.id">
                  {{ tag.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Signals</mat-label>
              <mat-select [formControlName]="'signalIds'" multiple>
                <mat-option *ngFor="let signal of signals" [value]="signal.id">
                  {{ signal.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div fxLayout="row">
  
            <mat-form-field class="pr-2" fxFlex="33" disabled>
              <mat-label>HeroBanner Url</mat-label>
              <input matInput [formControlName]="'heroBannerUrl'"/>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="33" disabled>
              <mat-label>Image Url</mat-label>
              <input matInput [formControlName]="'imageUrl'"/>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="33" disabled>
              <mat-label>Icon Url</mat-label>
              <input matInput [formControlName]="'iconUrl'"/>
            </mat-form-field>
          </div>
  
          <form
            class="flex flex-col w-full"
            [formGroup]="imagesForm"
          >
            <div fxLayout="row">
  
              <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Hero Banner File</mat-label>
                <ngx-mat-file-input [formControlName]="'herobannerFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                    appearance="standard"
                                    (change)="uploadHerobannerFile($event)"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
  
              <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Image File</mat-label>
                <ngx-mat-file-input [formControlName]="'imageFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                    appearance="standard"
                                    (change)="uploadImageFile($event)"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
  
              <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Icon File</mat-label>
                <ngx-mat-file-input [formControlName]="'iconFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                    appearance="standard"
                                    (change)="uploadIconFile($event)"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
            </div>
          </form>
  
  
          <div fxLayout="row">
  
            <mat-form-field class="pr-2" fxFlex="25" disabled>
              <mat-label>Icon With No BG Url</mat-label>
              <input matInput [formControlName]="'iconWithNoBGUrl'"/>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="25" disabled>
              <mat-label>Small Icon With BG Url</mat-label>
              <input matInput [formControlName]="'smallIconWithBGUrl'"/>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="25" disabled>
              <mat-label>Icon With BG Url</mat-label>
              <input matInput [formControlName]="'iconWithBGUrl'"/>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="25" disabled>
              <mat-label>Square Image Url</mat-label>
              <input matInput [formControlName]="'squareImageUrl'"/>
            </mat-form-field>
          </div>
  
          <form
            class="flex flex-col w-full"
            [formGroup]="imagesForm"
          >
            <div fxLayout="row">
  
              <mat-form-field class="pr-2" fxFlex="25">
                <mat-label>Icon With No BG File</mat-label>
                <ngx-mat-file-input [formControlName]="'iconWithNoBGFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                    appearance="standard"
                                    (change)="uploadIconWithNoBGFile($event)"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
  
              <mat-form-field class="pr-2" fxFlex="25">
                <mat-label>Small Icon With BG File</mat-label>
                <ngx-mat-file-input [formControlName]="'smallIconWithBGFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                    appearance="standard"
                                    (change)="uploadSmallIconWithBGFile($event)"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
  
              <mat-form-field class="pr-2" fxFlex="25">
                <mat-label>Icon With BG File</mat-label>
                <ngx-mat-file-input [formControlName]="'iconWithBGFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                    appearance="standard"
                                    (change)="uploadIconWithBGFile($event)"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
  
              <mat-form-field class="pr-2" fxFlex="25">
                <mat-label>Square Image File</mat-label>
                <ngx-mat-file-input [formControlName]="'squareImageFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                    appearance="standard"
                                    (change)="uploadSquareImageFile($event)"
                >
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
              </mat-form-field>
            </div>
          </form>
  
  
          <mat-form-field class="pr-2">
            <mat-label>Description</mat-label>
            <textarea matInput [formControlName]="'description'"></textarea>
          </mat-form-field>
  
          <div fxLayout="row">
  
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Short Description</mat-label>
              <textarea matInput [formControlName]="'shortDescription'"></textarea>
            </mat-form-field>
  
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Content Description</mat-label>
              <textarea matInput [formControlName]="'contentDescription'"></textarea>
            </mat-form-field>
  
          </div>
  
  
          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4"
            >
            <app-close-dialog></app-close-dialog>
              <div class="flex items-center">
                <div
                  class="flex items-center mr-4"
                  *ngIf="flashMessage"
                >
                  <ng-container
                    *ngIf="flashMessage === 'success'"
                  >
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">Blend Saved</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2">{{responseMessage}}</span>
                  </ng-container>
                </div>
                <button
                  mat-flat-button
                  [color]="'primary'"
                  [disabled]="saving"
  
                  (click)="saveBlend()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  
<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form
          class="flex flex-col w-full"
          [formGroup]="selectedProductForm"
        >
          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Name</mat-label>
              <input matInput [formControlName]="'name'"/>              
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Color</mat-label>
              <input matInput [formControlName]="'color'"
              [(colorPicker)]="color"
              [style.background]="color"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'btn btn-primary btn-xs'"
              [cpOutputFormat]="'hex'"
              [cpPosition]="'right'"
              [cpOKButton]="true"
              [style.color]="color"
              />
            </mat-form-field>
  
          </div>

          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Title</mat-label>
              <input matInput [formControlName]="'title'"/>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Text Color</mat-label>
              <input matInput [formControlName]="'textColor'"
              [(colorPicker)]="textColor"
              [style.background]="textColor"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'btn btn-primary btn-xs'"
              [cpOutputFormat]="'hex'"
              [cpPosition]="'right'"
              [cpOKButton]="true"
              [style.color]="textColor"              
              />
            </mat-form-field>

            
          </div>
  
          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Image url</mat-label>
              <input matInput [formControlName]="'imageUrl'"/>              
            </mat-form-field>   

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Surface Color</mat-label>
              <input matInput [formControlName]="'surfaceColor'"
              [(colorPicker)]="surfaceColor"
              [style.background]="surfaceColor"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'btn btn-primary btn-xs'"
              [cpOutputFormat]="'hex'"
              [cpPosition]="'right'"
              [cpOKButton]="true"
              [style.color]="surfaceColor"
              />
            </mat-form-field> 
          </div>

          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Icon url</mat-label>
              <input matInput [formControlName]="'iconUrl'"/>              
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Gradient Start Color</mat-label>
              <input matInput [formControlName]="'gradientStartColor'"
              [(colorPicker)]="gradientStartColor"
              [style.background]="gradientStartColor"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'btn btn-primary btn-xs'"
              [cpOutputFormat]="'hex'"
              [cpPosition]="'right'"
              [cpOKButton]="true"
              [style.color]="gradientStartColor"
              />
            </mat-form-field>

          </div>

          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="30">
              <mat-label>Parent Id</mat-label>
              <input matInput [formControlName]="'parentId'"/>             
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="20">
              <mat-label>Sort Order</mat-label>
              <input matInput [formControlName]="'sortOrder'"/>             
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Gradient End Color</mat-label>
              <input matInput [formControlName]="'gradientEndColor'"
              [(colorPicker)]="gradientEndColor"
              [style.background]="gradientEndColor"
              [cpCancelButton]="true"
              [cpCancelButtonClass]="'btn btn-primary btn-xs'"
              [cpOutputFormat]="'hex'"
              [cpPosition]="'right'"
              [cpOKButton]="true"
              [style.color]="gradientEndColor"
              />
            </mat-form-field>

          </div>


          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4"
            >
            <app-close-dialog></app-close-dialog>
              <div class="flex items-center">
                <div
                  class="flex items-center mr-4"
                  *ngIf="flashMessage"
                >
                  <ng-container
                    *ngIf="flashMessage === 'success'"
                  >
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">{{actionMessage}}</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2">{{responseMessage}}</span>
                  </ng-container>
                </div>
                <button
  
                  mat-flat-button
                  [color]="'primary'"
                  (click)="saveCategory()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  
import {Component, OnInit} from '@angular/core';
import {Blend} from '../offers/blend';
import {OffersService} from '../offers/offers.service';
import {Signal} from '../signals/signal';
import {SignalsService} from '../signals/signals.service';
import {MatDialog} from '@angular/material/dialog';
import {Tag} from '../tags/tags';
import {TagsService} from '../tags/tags.service';
import {CategoriesService} from '../categories/categories.service';
import {Category} from '../categories/category';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import { ViewImagesComponent } from '../../common-components/view-images/view-images/view-images.component';
import { BlendEditorComponent } from './blend-editor/blend-editor.component';

@Component({
  selector: 'app-offers',
  templateUrl: './blends.component.html',
  styleUrls: ['./blends.component.scss']
})
export class BlendsComponent implements OnInit {

  isLoading: boolean = false;
  blends: Blend[] = []; 
  fullBlends: Blend[] = [];
  signals: Signal[] = [];
  tags: Tag[] = [];
  categories: Category[];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = "";
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _offersService: OffersService,
    private _signalsService: SignalsService,
    private _tagsService: TagsService,
    private _categoriesService: CategoriesService,
    private _auth: AuthServiceClass,
    private _fuseConfirmationService: FuseConfirmationService,
    private _titleService: Title,
    public dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Blends');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:blend')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:blend');
    this.canEdit = perms.includes('update:blend');
    this.canDelete = perms.includes('delete:blend');

    this.isLoading = true;

    this.signals = await this._signalsService.getSignals();
    this.tags = await this._tagsService.getTags();
    this.categories = await this._categoriesService.getCategories();

    this.getBlends();
    
    
        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(500),
          switchMap(async (query) => {
            this.isLoading = true;
            this.query = query.trim();
            if(this.query){
              this.blends = this.blends.filter(o => o.name.toLowerCase().includes(this.query.toLowerCase()));
            }
            else{
              this.blends = this.fullBlends;
            }
  
          }),
          map(() => {
            this.isLoading = false;
          })
        )
        .subscribe();
  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.blends;
  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.blends = this.fullBlends;
    this.isLoading = false;
  }



  add() {
    let dialogref = this.dialog.open(BlendEditorComponent,
      {width: '900px', disableClose: true, data: {signals: this.signals, tags: this.tags, categories: this.categories}});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.getBlends();
      }
    });
  }

  edit(blend) {
    let dialogref = this.dialog.open(BlendEditorComponent,
      {width: '900px', disableClose: true, data: {signals: this.signals, tags: this.tags, categories: this.categories, blend: blend}});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.getBlends();
      }
    });
  }

  viewImages(blend) {
    let dialogref = this.dialog.open(ViewImagesComponent,
      {width: '425px', disableClose: false, data: {item: blend}});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.getBlends();
      }
    });
  }


  remove(blend) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Blend?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      (await this._offersService.deleteBlend(blend.id)).subscribe({
        next: async (e: any) => {
          if (e.message === null || e.message === undefined) {
            this.isLoading = true;
            await this.getBlends();
            this.isLoading = false;
            this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Blend : ' + blend.name + ' is deleted successfully'}
            });
          } 
        }, error: (err => {
           this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage: err.error.message}
            });
        })
      });
  });
}

  private async getBlends() {
    this.blends = await this._offersService.getBlends();
    for (let blend of this.blends) {
      blend.category = this.categories.filter(c => c.id === blend.categoryId)[0];
      blend.signals = [];
      for (let signalId of blend.signalIds) {
        let found = this.signals.filter(s => s.id === signalId);
        blend.signals.push(found[0]);
      }
      blend.rtags = [];
      for (let tagId of blend.tags) {
        let found = this.tags.filter(t => t.id === tagId);
        blend.rtags.push(found[0]);
      }
    }

    this.blends.sort(function(a, b) {
      return (a.sortOrder < b.sortOrder) ? -1 : ((a.sortOrder > b.sortOrder) ? 1 : 0);
    });
    
    this.fullBlends = this.blends;

  }
}

import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Routine, RoutineColor, RoutineFeedback, RoutineHeadband, RoutineItem, RoutineLight, RoutineSleepPad} from '../routine';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RoutinesService} from '../routines.service';
import {Tag} from '../../tags/tags';
import {Category} from '../../categories/category';
import {BlendPickerComponent} from '../blend-picker/blend-picker.component';
import { PlaybackModes, SleepMode } from '../../playback-modes';
import { SharedDataService } from './../shared.data.service';
import {Blend} from '../../offers/blend';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-routine-editor',
  templateUrl: './routine-editor.component.html',
  styleUrls: ['./routine-editor.component.scss']
})
export class RoutineEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  imagesForm: FormGroup;

  flashMessage: 'success' | 'error' | null = null;

  tags: Tag[];
  blends: Blend[];
  categories: Category[];
  routine: Routine;
  saving: boolean = false;
  responseMessage: string;
  actionMessage: string;
  cloning: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _routinesService: RoutinesService,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<RoutineEditorComponent>,
    public dialog: MatDialog,
    private sharedDataService: SharedDataService,
    private closeDialogComponent: CloseDialogComponent,
  @Inject(MAT_DIALOG_DATA) public data: any, //{blends: this.blends, tags: this.tags, categories: this.categories, routine: null}
  ) {
    this.tags = data.tags;
    this.blends = data.blends;
    this.categories = data.categories;
    if (data.routine) {
      this.routine = {...data.routine};
    }
    this.cloning = data.cloning;
  }

  ngOnInit(): void {
    this.imagesForm = this._formBuilder.group({
      imageFile: [''],
      iconFile: [''],
      herobannerFile: [''],
      iconWithNoBGFile: [''],
      largeRectImageFile: [''],
      wideRectImageFile: [''],
      squareImageFile: [''],
      iconFileData: [''],
      imageFileData: [''],
      herobannerFileData: [''],
      iconWithNoBGFileData: [''],
      largeRectImageFileData: [''],
      wideRectImageFileData: [''],
      squareImageFileData: [''],
    });

    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      sleepMode: [false],
      isPubliclyAvailable: [false],
      isHidden: [false],
      playbackId: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
      sortOrder: ['', [Validators.required]],
      tags: [[]],
      imageUrl: [''],
      iconUrl: [''],
      heroBannerUrl: [''],
      iconWithNoBGUrl: [''],
      squareImageUrl: [''],
      largeRectImageUrl: [''],
      wideRectImageUrl: [''],
      description: ['', [Validators.required]],
      shortDescription: ['', [Validators.required]],
      contentDescription: ['', [Validators.required]],
      items: ['', [Validators.required]]
    });

    if (this.routine) {
      this.selectedProductForm.get('name').setValue(this.routine.name);
      this.selectedProductForm.get('playbackId').setValue(this.routine.playbackId);
      this.selectedProductForm.get('sleepMode').setValue(this.routine.playbackModes.sleepMode.isEnabled);
      this.selectedProductForm.get('isPubliclyAvailable').setValue(this.routine.isPubliclyAvailable);
      this.selectedProductForm.get('isHidden').setValue(this.routine.isHidden);
      this.selectedProductForm.get('categoryId').setValue(this.routine.categoryId);

      this.selectedProductForm.get('sortOrder').setValue(this.routine.sortOrder);
      this.selectedProductForm.get('tags').setValue(this.routine.tags);
      this.selectedProductForm.get('imageUrl').setValue(this.routine.imageUrl);
      this.selectedProductForm.get('iconUrl').setValue(this.routine.iconUrl);
      this.selectedProductForm.get('heroBannerUrl').setValue(this.routine.heroBannerUrl);

      this.selectedProductForm.get('iconWithNoBGUrl').setValue(this.routine.iconWithNoBGUrl);
      this.selectedProductForm.get('largeRectImageUrl').setValue(this.routine.largeRectImageUrl);
      this.selectedProductForm.get('wideRectImageUrl').setValue(this.routine.wideRectImageUrl);
      this.selectedProductForm.get('squareImageUrl').setValue(this.routine.squareImageUrl);
      
      this.selectedProductForm.get('description').setValue(this.routine.description);
      this.selectedProductForm.get('shortDescription').setValue(this.routine.shortDescription);
      this.selectedProductForm.get('contentDescription').setValue(this.routine.contentDescription);
      this.selectedProductForm.get('items').setValue(this.routine.items);
    } else {
      this.routine = new Routine();
      this.routine.items = [];
      this.routine.tags = [];
      this.routine.rtags = [];
    }
  }

  
  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }

  
  saveRoutine() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const routine = this.selectedProductForm.getRawValue();
      routine.items = this.routine.items;

      routine.playbackModes = new PlaybackModes();
      routine.playbackModes.sleepMode = new SleepMode();
      routine.playbackModes.sleepMode.isEnabled = routine['sleepMode'];
      routine.isPubliclyAvailable = routine['isPubliclyAvailable'];
      routine.isHidden = routine['isHidden'];
      delete routine['sleepMode'];

      this.saving = true;

      if (this.routine.id) {
        if(this.cloning === true)
        {
          this._routinesService.newRoutine(routine).subscribe({
            next: async (e: any) => {
              if (e.data.id) {
                const images = this.imagesForm.getRawValue();
                if (images['herobannerFile'] === '') delete images['herobannerFile'];
                if (images['imageFile'] === '') delete images['imageFile'];
                if (images['iconFile'] === '') delete images['iconFile'];
                if (images['iconWithNoBGFile'] === '') delete images['iconWithNoBGFile'];
                if (images['largeRectImageFile'] === '') delete images['largeRectImageFile'];
                if (images['wideRectImageFile'] === '') delete images['wideRectImageFile'];
                if (images['squareImageFile'] === '') delete images['squareImageFile'];
                await this._routinesService.delay(); 
                this._routinesService.uploadRoutineImages(e.data.id, images).subscribe({
                    next: (e: any) => {
                      if (e) {
                        this.actionMessage = 'Routine Added';
                        this.showFlashMessage('success');
                      } else {
                        this.saving = false;
                        this.showFlashMessage('error');
                      }
                    },
                    error: (err) => {
                      this.saving = false;
  
                      this.showFlashMessage('error');
                    }
                  }
                );
              } else {
                this.saving = false;
  
                this.showFlashMessage('error');
              }
            }, error: (err => {
              this.responseMessage = err.error.message;
              this.saving = false;
  
              this.showFlashMessage('error');
            })
          });
        }
        else{
          this._routinesService.saveRoutine(this.routine.id, routine).subscribe({
            next: (e: any) => {
              if (e.data.id) {
                const images = this.imagesForm.getRawValue();
                if (images['herobannerFile'] === '') delete images['herobannerFile'];
                if (images['imageFile'] === '') delete images['imageFile'];
                if (images['iconFile'] === '') delete images['iconFile'];
                if (images['iconWithNoBGFile'] === '') delete images['iconWithNoBGFile'];
                if (images['largeRectImageFile'] === '') delete images['largeRectImageFile'];
                if (images['wideRectImageFile'] === '') delete images['wideRectImageFile'];
                if (images['squareImageFile'] === '') delete images['squareImageFile'];
  
                this._routinesService.uploadRoutineImages(e.data.id, images).subscribe({
                    next: (e: any) => {
                      if (e) {
                        this.actionMessage = 'Routine Updated';
                        this.showFlashMessage('success');
                      } else {
                        this.saving = false;
                        this.showFlashMessage('error');
                      }
                    },
                    error: (err) => {
                      this.responseMessage = err.error.message;
                      this.saving = false;
  
                      this.showFlashMessage('error');
                    }
                  }
                );
              } else {
                this.saving = false;
  
                this.showFlashMessage('error');
              }
            }, error: (err => {
              this.responseMessage = err.error.message;
              this.saving = false;
  
              this.showFlashMessage('error');
            })
          });
        }
        
      } else {
        this._routinesService.newRoutine(routine).subscribe({
          next: async (e: any) => {
            if (e.data.id) {
              const images = this.imagesForm.getRawValue();
              if (images['herobannerFile'] === '') delete images['herobannerFile'];
              if (images['imageFile'] === '') delete images['imageFile'];
              if (images['iconFile'] === '') delete images['iconFile'];
              if (images['iconWithNoBGFile'] === '') delete images['iconWithNoBGFile'];
              if (images['largeRectImageFile'] === '') delete images['largeRectImageFile'];
              if (images['wideRectImageFile'] === '') delete images['wideRectImageFile'];
              if (images['squareImageFile'] === '') delete images['squareImageFile'];
              await this._routinesService.delay();
              this._routinesService.uploadRoutineImages(e.data.id, images).subscribe({
                  next: (e: any) => {
                    if (e) {
                      this.actionMessage = 'Routine Added';
                      this.showFlashMessage('success');
                    } else {
                      this.saving = false;
                      this.showFlashMessage('error');
                    }
                  },
                  error: (err) => {
                    this.saving = false;

                    this.showFlashMessage('error');
                  }
                }
              );
            } else {
              this.saving = false;

              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.saving = false;

            this.showFlashMessage('error');
          })
        });
      }

    }
  }

  addItem() {
    let dialogref = this.dialog.open(BlendPickerComponent,
      {width: '700px', disableClose: true, data: this.blends});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        let item = new RoutineItem();
        item.signalBundleId = res.blendId;
        item.suggestedDuration = res.suggestedDuration;
        item.sortOrder = res.sortOrder;
        item.blend = this.blends.find(b => b.id === res.blendId);
        item.feedback = new RoutineFeedback();
        item.feedback.headBand = new RoutineHeadband();
        item.feedback.headBand.haptics = res.haptics;
        item.feedback.sleepPad = new RoutineSleepPad();
        item.feedback.sleepPad.haptics = res.sleeppadhaptics;
        item.feedback.headBand.light = new RoutineLight();
        item.feedback.headBand.light.mode = res.lightMode;
        item.feedback.headBand.light.brightness = res.brightness;
        item.feedback.headBand.light.color = new RoutineColor();
        item.feedback.headBand.light.color.red = res.red;
        item.feedback.headBand.light.color.green = res.green;
        item.feedback.headBand.light.color.blue = res.blue;

        this.routine.items.push(item);
        this.routine.items.sort((a,b) => a.sortOrder - b.sortOrder );
        this.selectedProductForm.get('items').setValue(this.routine.items);
      }
    });
  }

  editItem(index) {
    this.sharedDataService.setData(this.routine.items[index]);
    let dialogref = this.dialog.open(BlendPickerComponent,
      {width: '700px', disableClose: true, data: this.blends});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        let item = new RoutineItem();
        item.signalBundleId = res.blendId;
        item.suggestedDuration = res.suggestedDuration;
        item.sortOrder = res.sortOrder;
        item.blend = this.blends.find(b => b.id === res.blendId);
        item.feedback = new RoutineFeedback();
        item.feedback.headBand = new RoutineHeadband();
        item.feedback.headBand.haptics = res.haptics;
        item.feedback.sleepPad = new RoutineSleepPad();
        item.feedback.sleepPad.haptics = res.sleeppadhaptics;
        item.feedback.headBand.light = new RoutineLight();
        item.feedback.headBand.light.mode = res.lightMode;
        item.feedback.headBand.light.brightness = res.brightness;
        item.feedback.headBand.light.color = new RoutineColor();
        item.feedback.headBand.light.color.red = res.red;
        item.feedback.headBand.light.color.green = res.green;
        item.feedback.headBand.light.color.blue = res.blue;

        this.routine.items[index] = item;
      }
    });
  }

  viewItem(index){
    this.sharedDataService.setData(this.routine.items[index]);
    this.sharedDataService.setViewDataFlag(true);
    let dialogref = this.dialog.open(BlendPickerComponent,
      {width: '700px', disableClose: true, data: this.blends});
  }
  

  removeItem(index) {
    this.routine.items.splice(index, 1);
  }

  uploadHerobannerFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        herobannerFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        herobannerFileData: file,
      });
      this.imagesForm.get('herobannerFile').updateValueAndValidity();
    }
  }

  uploadImageFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        imageFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        imageFileData: file,
      });
      this.imagesForm.get('imageFile').updateValueAndValidity();
    }

  }

  uploadIconFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        iconFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        iconFileData: file,
      });
      this.imagesForm.get('iconFile').updateValueAndValidity();
    }

  }

  uploadIconWithNoBGFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        iconWithNoBGFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        iconWithNoBGFileData: file,
      });
      this.imagesForm.get('iconWithNoBGFile').updateValueAndValidity();
    }
  }

  uploadLargeRectImageFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        largeRectImageFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        largeRectImageFileData: file,
      });
      this.imagesForm.get('largeRectImageFile').updateValueAndValidity();
    }

  }

  uploadWideRectImageFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        wideRectImageFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        wideRectImageFileData: file,
      });
      this.imagesForm.get('wideRectImageFile').updateValueAndValidity();
    }

  }

  uploadSquareImageFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        squareImageFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        squareImageFileData: file,
      });
      this.imagesForm.get('squareImageFile').updateValueAndValidity();
    }

  }
}
